<template>
  <fa-modal :visible="visible" title="操作指引" @cancel="close" :footer="null" width="75%">
    <img src="@/assets/guide.gif" alt="" width="100%" height="100%" />
  </fa-modal>
</template>

<script>
export default {
  data() {
    return {}
  },
  props: {
    visible: Boolean,
  },
  computed: {},
  methods: {
    /**
     * @desc 关闭
     */
    close() {
      this.$emit('update:visible', false)
    },
  },
}
</script>
