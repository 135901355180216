import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Button, message, Icon, Card, Spin, Input, Descriptions, Alert, Divider, Modal, Popover, Table, Select, Dropdown, Menu, Upload } from '@fk/faicomponent';
import '@fk/faicomponent/lib/button/style/css'
import "@fk/faicomponent/lib/message/style/css";
import "@fk/faicomponent/lib/card/style/css";
import "@fk/faicomponent/lib/spin/style/css";
import "@fk/faicomponent/lib/input/style/css";
import "@fk/faicomponent/lib/descriptions/style/css";
import "@fk/faicomponent/lib/alert/style/css";
import "@fk/faicomponent/lib/modal/style/css";
import "@fk/faicomponent/lib/popover/style/css";
import "@fk/faicomponent/lib/divider/style/css";
import "@fk/faicomponent/lib/table/style/css";
import "@fk/faicomponent/lib/select/style/css";
import "@fk/faicomponent/lib/dropdown/style/css";
import "@fk/faicomponent/lib/menu/style/css";
import "@fk/faicomponent/lib/upload/style/css";
Vue.use(Button);
Vue.use(Icon);
Vue.use(Card);
Vue.use(Spin);
Vue.use(Input);
Vue.use(Descriptions);
Vue.use(Alert);
Vue.use(Divider);
Vue.use(Modal);
Vue.use(Popover);
Vue.use(Table);
Vue.use(Select);
Vue.use(Dropdown);
Vue.use(Menu);
Vue.use(Upload);

// 放大图片
// import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
// import 'vue-directive-image-previewer/dist/assets/style.css'
// Vue.use(VueDirectiveImagePreviewer)

Vue.prototype.$message = message;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
