/**
 * @desc 获取浏览器版本信息
 */
import Bowser from "bowser";

export const getBrowserVersion = () => {
    let browserInfo = Bowser.getParser(window.navigator.userAgent).getBrowser();
    return browserInfo.name + ' ' + browserInfo.version;
};

/**
 * @desc 获取操作系统
 */
export const getOS = () => {
    const deviceInfo = Bowser.getParser(window.navigator.userAgent);
    let osInfo = deviceInfo.getOS();
    let platformInfo = deviceInfo.getPlatform();
    let os = osInfo.name + ' ';

    if (!!osInfo.versionName) {
        os += osInfo.versionName;
    } else {
        os += osInfo.version;
    }
    return os + getPlatformTypeName(platformInfo.type);
};

export const getPlatformTypeName = (type) => {
    if (type === 'desktop') {
        return "（桌面端）";
    } else if (type === 'mobile') {
        return "（手机端）";
    } else if (type === 'tablet') {
        return "（平板端）";
    } else if (type === 'tv') {
        return "（TV端）";
    }

    return "";
};

/**
 * @desc 获取浏览器信息
 */
export const getBrowser = () => {
    let browser = 'Other'
    const userAgent = navigator.userAgent.toLowerCase()
    const Browser = {
        msie: 'IE',
        uc: 'UC',
        opera: 'Opera',
        qqbrowser: 'QQ',
        tencenttraveler: 'QQ',
        metasr: '搜狗浏览器',
        '360se': '360浏览器',
        'the world': '世界之窗浏览器',
        maxthon: '遨游浏览器',
        firefox: 'Firefox',
        edge: 'edge',
        chrome: 'Chrome',
    }
    for (let item in Browser) {
        if (userAgent.includes(item)) {
            browser = Browser[item]
        }
    }
    return browser
};

//App.vue
export const _isMobile = () => {
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}
