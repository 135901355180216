import { initFaicaptcha } from "@fk/faicaptcha";
import "@fk/faicaptcha/dist/css/faicaptcha.css";
import axios from "axios";
import qs from 'qs'

const PROTOCOL = window.location.protocol //当前协议
const HOST = window.location.host //当前协议
const LUCA_BASE_URL = process.env.NODE_ENV === 'production' ? `${PROTOCOL}//${HOST}` : `${PROTOCOL}//luca.dev.cc` //待英姐给api

export function callFaicaptcha(doSomething) {
    let ak = ''
    initFaicaptcha(
        {
            genAccessKey(cb) {
                // 业务发送请求获取 ak
                axios({
                    type: 'get',
                    url: `${LUCA_BASE_URL}/cap/get`
                }).then(res => {
                    ak = res.data.data.ak
                    cb(res.data.data.ak);
                })
            }
        },
        async function (checkSign) {
            // 拿到验签，进行后续操作
            const formData = qs.stringify({
                ak,
                sign: checkSign,
            })
            axios({
                method: 'post',
                url: `${LUCA_BASE_URL}/cap/validate`,
                data: formData,
            }).then((res) => {
                if (res.data.data.rt) {
                    doSomething(res.data.data.key)
                } else {
                    console.log('验证码校验失败！')
                }
            }).catch(error => {
                console.log('验证码校验失败！')
            })
        },
    );
}
