

<template>
  <div id="check-page">
    <div class="title">网站自助检测及修复工具</div>
    <!-- 输入域名检测 -->
    <div class="search-content row-box" style="position: relative">
      <fa-popover placement="bottomLeft" :visible="inputModule.validatorVisible">
        <span slot="content">请输入正确的域名！</span>
        <fa-input-search
          placeholder="请输入网址进行快速检测，如：www.baidu.com"
          @search="handleValidateDomain"
          v-model="inputDomain"
          style="width: 700px"
          allowClear
          :disabled="inputModule.disabled"
          enterButton="快速检测"
          size="large"
          @pressEnter="handleValidateDomain"
        />
      </fa-popover>
      <fa-button type="link" style="position: absolute; bottom: 0; right: 0" @click="showGuide">操作指引</fa-button>
    </div>

    <fa-spin :spinning="spinning" :class="{ 'check-empty': !checkComplete }">
      <!-- 检测详情 -->
      <div class="row-box">
        <h4 style="display: inline-block" class="module-title">检测详情</h4>
        <fa-descriptions bordered :column="1" size="small" style="min-width: 1080px">
          <fa-descriptions-item v-if="!!fullDomain" label="检测域名">
            {{ fullDomain }}
            <template v-if="!checkDomainInfo.isOfficial">
              （<span> 服务器状态码:{{ checkDomainInfo.statusCode || '未知' }} </span>）
            </template>
          </fa-descriptions-item>
          <fa-descriptions-item v-if="!!checkDomainInfo.cname" label="域名指向">
            <span v-html="checkDomainInfo.cname"></span>
            <span v-if="!!checkDomainInfo.cnameIp">（{{ checkDomainInfo.cnameIp }}）</span>
          </fa-descriptions-item>
          <fa-descriptions-item label="检测时间"> {{ testTime }} </fa-descriptions-item>
          <fa-descriptions-item label="出口IP"> {{ wangsu.uip }} {{ ipInfo.uipInfo }}</fa-descriptions-item>
          <fa-descriptions-item label="递归DNS"> {{ ipInfo.dnsAddr }} </fa-descriptions-item>
          <fa-descriptions-item v-if="!!checkDomainInfo.ns1" label="域名NS">
            {{ checkDomainInfo.ns1 }} {{ checkDomainInfo.ns2 }} {{ checkDomainInfo.nsManager }}
          </fa-descriptions-item>
          <fa-descriptions-item v-if="!!checkDomainInfo.authStatus" label="实名状态">
            {{ checkDomainInfo.authStatus }}
          </fa-descriptions-item>
          <fa-descriptions-item v-if="!!checkDomainInfo.beianStatus" label="备案状态">
            {{ checkDomainInfo.beianStatus }}
          </fa-descriptions-item>
          <fa-descriptions-item label="操作系统"> {{ OS_VERSION }} </fa-descriptions-item>
          <fa-descriptions-item label="浏览器版本信息"> {{ BROWSER_VERSION }} </fa-descriptions-item>
        </fa-descriptions>
        <template v-if="hasIssueList">
          <fa-divider dashed>相关问题</fa-divider>
          <fa-table :columns="columns" :dataSource="checkDomainInfo.issueList" bordered rowKey="code" :pagination="false">
            <span slot="name" slot-scope="text, record, index">{{ index + 1 }}</span>
            <span slot="resolution" slot-scope="resolution, record" :style="{ color: record.type === 1 ? 'red' : 'orange' }" v-html="resolution" />
          </fa-table>
        </template>
      </div>
      <template v-if="checkComplete">
        <!-- 相关工具 -->
        <div class="row-box">
          <h4 style="display: inline-block" class="module-title">相关工具</h4>
          <fa-alert type="warning" style="margin-bottom: 15px">
            <span slot="message">提示：为了更快定位与解决您的问题，请下载相应的工具，解压并<span style="color: red">以管理员身份执行</span>，谢谢您的合作！</span>
          </fa-alert>
          <fa-button style="margin-right: 20px" type="primary" icon="file-zip" @click="downloadCheckTools()" :loading="tools.checkLoading">深度检测工具</fa-button>
          <fa-dropdown-button type="primary" @click="downloadFixTools" placement="topRight" style="margin-right: 20px">
            <fa-icon type="loading" v-if="tools.optimizeLoading" />
            <fa-icon type="tool" v-else />
            {{ optBatName }}
            <fa-menu slot="overlay" @click="selectOptBat">
              <fa-menu-item v-for="item in optBatOptions" :key="item.key">
                {{ item.value }}
              </fa-menu-item>
            </fa-menu>
          </fa-dropdown-button>
          <fa-upload
              class="har-upload-list-inline"
              :name="harUploadConf.name"
              :accept="harUploadConf.accept"
              :action="harUploadConf.action"
              :data="harUploadConf.data"
              :headers="harUploadConf.headers"
              :file-list="harUploadConf.fileList"
              :custom-request="harFileUpload"
              :before-upload="harBeforeUpload"
              :showUploadList="true"
              @change="onHandlerHarFileChange"
          >
            <fa-button type="primary">
              <fa-icon type="upload" />
              上传Har文件
            </fa-button>
          </fa-upload>
        </div>
        <!-- 网站输出 -->
        <div class="row-box" v-if="!isBaidu">
          <h4 style="display: inline-block" class="module-title">网站输出</h4>
          <fa-spin tip="网站性能上报中，请稍后..." :spinning="iframe.loading">
            <div class="iframe-container row-box">
              <iframe id="iframeEle" ref="iframeEle" class="iframeA" :src="iframe.url" frameborder="0" scrolling="yes"> </iframe>
            </div>
          </fa-spin>
        </div>
      </template>
    </fa-spin>
    <!-- 操作指引弹窗 -->
    <guide :visible.sync="guideVisible" />
  </div>
</template>

<script>
import axios from 'axios';
import qs from 'qs';
import moment from 'moment';
import Guide from './guide.vue';
import {getBrowserVersion, getBrowser, getOS} from './request';
import { callFaicaptcha } from '@/utils/captcha';
import '../utils/jquery-1.9.1.min';
import '../utils/fai.src';

const PROTOCOL = window.location.protocol //当前协议
const HOST = window.location.host //主机和端口
const LUCA_BASE_URL = process.env.NODE_ENV === 'production' ? `${PROTOCOL}//${HOST}` : `${PROTOCOL}//luca.dev.cc`

const BROWSER_VERSION = getBrowserVersion() //浏览器版本信息
const OS_VERSION = getOS(); // 操作系统版本信息

const columns = [
  {
    title: '序列',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' },
    width: 70,
  },
  {
    title: '错误代码',
    dataIndex: 'code',
    width: 90,
  },
  {
    title: '问题描述',
    dataIndex: 'issue',
  }
]

export default {
  components: { Guide },
  data() {
    return {
      check: '', //url携带的id

      inputDomain: '', //搜索框显示的的域名

      spinning: false, //检测loading

      checkComplete: false, //是否检测完成

      fullDomain: '', //用户输入后经过处理的域名

      protocol: '', //用户输入的域名协议

      checkDomainInfo: {}, //域名检测结果

      id: '', //记录id

      testTime: moment().format('YYYY-MM-DD HH:mm:ss'), //当前时间

      // 网宿相关信息
      wangsu: {
        uip: '',
        udns: '',
      },

      // 出口ip等基本信息
      ipInfo: {
        uipInfo: '',
        dnsAddr: '',
      },

      //  输入域名检测相关信息
      inputModule: {
        validatorVisible: false, //输入校验标识符
        disabled: false, //是否禁用
      },

      //  iframe相关信息
      iframe: {
        url: '', //iframe的url
        loading: false, //iframe网页加载状态
      },

      //  相关工具信息
      tools: {
        checkLoading: false, //下载检测工具按钮loading
        optimizeLoading: false, //下载修复工具按钮loading
      },

      // 操作指引弹窗
      guideVisible: false,

      reportKey: '',

      key: '', //验证码key，5分钟内有效

      BROWSER_VERSION, //浏览器版本信息
      OS_VERSION, // 操作系统版本信息
      columns,
      optBatType: 'optimize', //优化工具类型
      // 优化工具选项
      optBatOptions: [
        {
          key: 'optimize',
          value: '本地DNS优化工具',
        },
        {
          key: '114',
          value: '114DNS修改工具',
        },
        {
          key: '223',
          value: '阿里DNS修改工具',
        },
        {
          key: '119',
          value: '119DNS修改工具',
        },
        {
          key: 'baidu',
          value: '百度DNS修改工具',
        },
        {
          key: 'google',
          value: '谷歌DNS修改工具',
        },
        {
          key: 'auto',
          value: '恢复自动DNS工具',
        },
      ],

      // har文件上传配置
      harUploadConf: {
        accept: ".har",
        name: "file",
        action: `${LUCA_BASE_URL}/rest/domain/uploadHar`,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: {
          id: ""
        },
        fileList: []
      },
    }
  },
  computed: {
    /**
     * @desc 是否有相关问题
     * @returns {Boolean}
     */
    hasIssueList() {
      return this.checkDomainInfo?.issueList?.length > 0
    },
    /**
     * @desc 输入检测页面是否为百度
     * @returns {Boolean}
     */
    isBaidu() {
      return this.inputDomain.indexOf('baidu.com') !== -1
    },
    /**
     * @desc 选中的优化工具名称
     * @returns {String}
     */
    optBatName() {
      const selectedOptBat = this.optBatOptions.filter((item) => item.key === this.optBatType)
      return selectedOptBat[0].value
    },
  },
  created() {
    //如果检测域名存在跳转，后端会返回该链接给用户重新检测，redictDomain由后端提供。参考链接：https://report.co99.net/?redictDomain=hhh.com
    this.defaultShowInfo();

    const redictDomain = this.$route.query.redictDomain
    if (!!redictDomain) {
      this.inputDomain = redictDomain
      this.handleValidateDomain()
    }

    //后端生成的url。参考链接：https://report.co99.net/?check=pPzfUWORBxAJY95f3UVKQUbKHx7EV8rQ（此时检测的时候不需要向后端再create一条记录）
    this.check = this.$route.query.check
    if (!!this.check) {
      this.getDomain(this.check)
    }
  },
  methods: {
    async defaultShowInfo() {
      this.spinning = true;
      await this.getBaseInfo();
      this.spinning = false;
    },
    /**
     * @desc 链接有url的话，则获取该域名，用户不需要手动输入域名
     * @param {String} check url的check参数
     */
    getDomain(check) {
      axios
        .post(`${LUCA_BASE_URL}/rest/domain/hit/${check}`)
        .then((res) => {
          const { code, data = {} } = res.data
          if (code === 200) {
            this.inputDomain = data.domain
            this.handleValidateDomain()
          } else {
            this.$message.error('您的链接有误！')
          }
        })
        .catch((error) => {
          console.log('Failed to get domain name!')
        })
    },
    /**
     * @desc 格式校验
     */
    handleValidateDomain() {
      const value = this.inputDomain //获取检测输入框的值
      if (!!value) {
        const checkUri = value.trim().toLowerCase()
        this.checkUri = checkUri
        // 域名输入检查
        if (checkUri.indexOf('/') !== -1) {
          const _aurl = checkUri.split('/')
          if (checkUri.indexOf('http://') == -1 && checkUri.indexOf('https://') == -1) {
            this.fullDomain = _aurl[0]
          } else {
            this.fullDomain = _aurl[2]
            this.protocol = _aurl[0]
          }
        } else {
          this.fullDomain = checkUri
        }

        //中文域名处理
        var hasChinese = false
        var hasChinese1 = false
        var domainName, domainName1, edomainName, edomainName1
        if (this.fullDomain.indexOf('.') < this.fullDomain.lastIndexOf('.')) {
          domainName = this.fullDomain.split('.')[1]
          if (this.fullDomain.split('.')[2] != null) {
            domainName1 = this.fullDomain.split('.')[2]
            for (var i = domainName1.length - 1; i >= 0; i--) {
              var c = domainName1.charAt(i)
              if (Fai.isChinese(c)) {
                hasChinese1 = true
              }
            }
          }
        } else {
          domainName = this.fullDomain.split('.')[0]
        }
        for (var i = domainName.length - 1; i >= 0; i--) {
          var c = domainName.charAt(i)
          if (Fai.isChinese(c)) {
            hasChinese = true
          }
        }
        if (hasChinese) {
          edomainName = 'xn--' + Fai.punycode.encode(domainName)
          this.fullDomain = this.fullDomain.replace(domainName, edomainName)
        }
        if (hasChinese1) {
          edomainName1 = 'xn--' + Fai.punycode.encode(domainName1)
          this.fullDomain = this.fullDomain.replace(domainName1, edomainName1)
        }

        callFaicaptcha((key) => {
          this.key = key
          // 验证码校验成功后
          this.handleCheckDomain(this.fullDomain)
        })
      } else {
        this.inputModule.validatorVisible = true
      }
    },
    /**
     * @desc 域名校验成功后，开始检测
     * @param {String} validDomain
     */
    async handleCheckDomain(validDomain) {
      if (this.checkComplete) {
        this.initPage()
      }
      this.spinning = true
      this.inputModule.validatorVisible = false //去掉格式校验不通过的提示
      this.inputModule.disabled = true //输入框和按钮置为禁用状态
      try {
        await this.getBaseInfo()

        // 如果当前url携带了check，并且是该页面第一次检测，则不新建一个id记录
        if (!!this.check) {
          this.id = this.check;
          this.harUploadConf.data.id = this.check;
        }
        // 否则新建一个记录
        else {
          const { id } = await this.addRecord(validDomain);
          this.id = id;
          this.harUploadConf.data.id = this.id;
        }
        const { data } = await this.checkDomain(validDomain)
        this.checkDomainInfo = data //保存检测域名的信息
        this.iframe.url = `${PROTOCOL}//${this.fullDomain}?check=${this.id}&_preview=true` //iframe性能上报
        this.checkComplete = true
        this.addPageView() //增加页面访问量
        this.checkReport() //检测上报的问题
      } catch (error) {
        console.log('Detection failed!', error);
        this.$message.error('域名检测失败！' + error);
      } finally {
        this.inputModule.disabled = false //输入框置为正常状态
        this.spinning = false
      }
    },
    /**
     * @desc 初始化页面
     */
    initPage() {
      this.checkComplete = false
      this.check = '' //如果url携带了check参数，则用户第二次查询时，需要清理check
      this.checkDomainInfo = {} //清空域名检测信息
    },
    /**
     * @desc 后台添加一条记录
     */
    addRecord() {
      const formData = qs.stringify({
        domain: this.fullDomain,
      })
      return new Promise((resolve, reject) => {
        axios({
          method: 'post',
          url: `${LUCA_BASE_URL}/rest/domain/create`,
          data: formData,
          headers: {
            capKey: this.key,
          },
        })
          .then((res) => {
            if (res.data.code === 200) {
              resolve(res.data.data)
            } else {
              reject(res.data.msg)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    /**
     * @desc 检测域名
     * @param {String} validDomain
     */
    checkDomain(validDomain) {
      const OS = getOS() //操作系统
      const BROWSER = getBrowser() //浏览器信息
      const encodeURI = this.checkUri.replaceAll("#", "%23")

      return new Promise((resolve, reject) => {
        axios({
          type: 'get',
          url: `${LUCA_BASE_URL}/check/checkDomainInfo?id=${this.id}&protocol=${PROTOCOL}&topDomain=${validDomain}&fullDomain=${this.fullDomain}&checkUri=${encodeURI}&ip=${this.wangsu.uip}&ipinfo=${this.ipInfo.uipInfo}&dns=${this.wangsu.udns}&browser=${BROWSER}&os=${OS}&dnsAddr=${this.ipInfo.dnsAddr}`,
          headers: {
            capKey: this.key,
          },
        })
          .then((res) => {
            if (res.data.code === 200) {
              resolve(res.data);
            } else {
              reject(res.data.msg);
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    /**
     * @desc 增加页面访问量
     */
    addPageView() {
      const { issueList = [] } = this.checkDomainInfo
      issueList.forEach((item) => {
        let reportStatus = 'green'
        if (item.type === 1) {
          reportStatus = 'red'
        } else if (item.type === 2) {
          reportStatus = 'yellow'
        }
        const formData = qs.stringify({
          appid: 3,
          reportStatus,
        })
        axios({
          method: 'post',
          url: `${LUCA_BASE_URL}/check/ip/add`,
          data: formData,
          headers: {
            capKey: this.key,
          },
        })
          .then((res) => {
            if (res.data.code === 200) {
              this.reportKey = res.data.msg
            }
          })
          .catch((error) => {
            console.log('add ip report err;')
          })
      })
    },
    /**
     * @desc 检测上报的问题
     */
    checkReport() {
      const { issueList = [], eid } = this.checkDomainInfo
      //英姐说eid为空时，不上报问题哦
      if (eid === '') {
        return
      }
      issueList.forEach((item) => {
        const issue = Fai.encodeUrl(item.issue)
        const resolution = Fai.encodeUrl(item.resolution)
        axios({
          type: 'get',
          url: `${LUCA_BASE_URL}/check/checkReport?eid=${eid}&ptext=${issue}&rtext=${resolution}&type=${item.type}`,
          headers: {
            capKey: this.key,
          },
        })
          .then((res) => {
            console.log('res', res)
          })
          .catch((error) => {
            console.log('check report err;')
          })
      })
    },
    /**
     * @desc 点击辅助工具
     */
    async downloadCheckTools() {
      this.tools.checkLoading = true
      try {
        const toolsData = await this.hasExisId() //获取id相关数据
        const packageName = `检测工具_${toolsData.id}_${toolsData.domain}.zip` //压缩包名称
        const res = await this.getCheckTools() //压缩包数据
        this.saveCheckTools(res, packageName) //下载检测工具
      } catch (error) {
        this.$message.error('下载失败！')
        console.log('Download helper failed')
      } finally {
        this.tools.checkLoading = false
      }
    },
    /**
     * @desc 判断id是否存在
     */
    hasExisId() {
      return new Promise((resolve, reject) => {
        axios({
          type: 'get',
          url: `${LUCA_BASE_URL}/check/detect?id=${this.id}&domain=${this.fullDomain}`,
          headers: {
            capKey: this.key,
          },
        })
          .then((res) => {
            resolve(res.data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    /**
     * @desc 获取检测工具包
     */
    getCheckTools() {
      return new Promise((resolve, reject) => {
        axios({
          type: 'get',
          url: "https://1.s21i.faiusr.com/0/ABUIABBPGAAg0ZjHuQYokIfu6QM?f=check_ClickTool.zip&v=1",
          responseType: 'blob',
        })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            if (error.response.code === 445) {
              this.handleValidateDomain()
            }
            if (error.response.status === 500) {
              this.$message.error('下载失败！请联系技术支持处理！')
            }
            reject(error)
          })
      })
    },

    /**
     * @desc 下载检测工具
     * @param {String} res 压缩包数据
     * @param {String} packageName 压缩包名称
     */
    saveCheckTools(res, packageName) {
      let blob = new Blob([res], { type: 'application/zip' })
      let url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = packageName
      link.click()
      URL.revokeObjectURL(url)
    },

    /**
     * @desc 下载修复工具
     */
    downloadFixTools() {
      const OS = getOS() //操作系统
      const BROWSER = getBrowser() //浏览器信息
      this.tools.optimizeLoading = true
      axios({
        type: 'get',
        responseType: 'blob',
        url: `${LUCA_BASE_URL}/check/download/optBat?domain=${this.fullDomain}&browser=${BROWSER}&os=${OS}&type=${this.optBatType}`,
        headers: {
          capKey: this.key,
        },
      })
        .then((res) => {
          let blob = new Blob([res.data], { type: 'application/zip' })
          let url = window.URL.createObjectURL(blob)
          const link = document.createElement('a')
          link.href = url
          link.download = this.optBatName //下载包名称和用户选中的优化工具对应
          link.click()
          URL.revokeObjectURL(url)
        })
        .catch((error) => {
          if (error.response.code === 445) {
            this.handleValidateDomain()
          }
          if (error.response.status === 500) {
            this.$message.error('下载失败！请联系技术支持处理！')
          }
          console.log('Failed to download repair tool!')
        })
        .finally(() => {
          this.tools.optimizeLoading = false
        })
    },

    /**
     * @desc Har文件上传Change事件处理器
     * @param info
     */
    onHandlerHarFileChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1).map(file => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.harUploadConf.fileList = fileList;
      if (info.file.status === "done") {
        if (!!!info.file.response) {
          this.$message.error(`【${info.file.name}】上传失败; 失败原因：系统未知错误.`);
        } else if (info.file.response.code === 200) {
          this.$message.success(`【${info.file.name}】上传成功`);
        } else {
          this.$message.success(`【${info.file.name}】上传失败; 失败原因：${info.file.response.msg}`);
        }
      } else if (info.file.status === "error") {
        this.$message.error(`【${info.file.name}】上传失败; 失败原因：${info.file.error.message}`);
      }
    },
    harFileUpload({
      action, data, file, filename, headers,
      onError, onProgress, onSuccess, withCredentials,
    }) {
      const formData = new FormData();
      if (data) {
        Object.keys(data).map(key => {
          formData.append(key, data[key]);
        });
      }
      formData.append(filename, file);

      axios.post(action, formData, {
        withCredentials,
        headers,
        onUploadProgress: ({ total, loaded }) => {
          onProgress(
              {
                percent: Math.round((loaded / total) * 100).toFixed(2),
              },
              file
          );
        },
      }).then(({data: response}) => {
        onSuccess(response, file);
      }).catch(onError);
      return {
        abort() {
          console.log("upload progress is aborted.");
        },
      }
    },
    harBeforeUpload(file) {
      let isValid = file.name.substr(file.name.length - 4) === ".har";
      if (!isValid) {
        file.status = "error";
        file.error = new Error("文件格式错误.");
      }
      return isValid;
    },
    /**
     * @desc 获取基本信息
     */
    async getBaseInfo() {
      try {
        const wangsu = await this.getWangsuInfo()
        const ipInfo = await this.getCheckInfo(wangsu)

        this.wangsu.udns = wangsu.dns[0]
        this.wangsu.uip = wangsu.cli

        this.ipInfo.uipInfo = ipInfo.ipAddr
        this.ipInfo.dnsAddr = ipInfo.dnsAddr
      } catch {
        console.log('Failed to get basic information!')
      }
    },

    /**
     * @desc 获取网宿相关信息
     */
    getWangsuInfo() {
      return new Promise((resolve, reject) => {
        axios({
          type: 'get',
          url: 'https://111102fef5404903013f583f92cfd9e7907953http1.dns.wangsu.com/result?key=wstong&form=json',
        })
          .then((res) => {
            resolve(res.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },

    /**
     * @desc 获取出口ip等基本信息
     * @param {Object} info 网宿相关信息
     */
    getCheckInfo(info) {
      const eid = 0
      return new Promise((resolve, reject) => {
        axios({
          type: 'get',
          url: `${LUCA_BASE_URL}/check/checkIpInfo?ip=${info.cli}&dns=${info.dns[0]}&eid=${eid}`,
          headers: {
            capKey: this.key,
          },
        })
          .then((res) => {
            if (res.data.code === 200) {
              resolve(res.data.data)
            } else {
              reject(res.data.msg)
            }
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    /**
     * @desc 打开操作指引弹窗
     */
    showGuide() {
      this.guideVisible = true
    },
    /**
     * @desc 选择优化工具
     * @param {String} key 优化工具key
     */
    selectOptBat({ key }) {
      this.optBatType = key
    },
  },
}
</script>

<style lang="scss" scoped>
#check-page {
  // width: 80%;
  max-width: 1080px;
  margin: 0 auto;
  padding: 36px 24px;

  display: grid;

  .row-box {
    margin-bottom: 24px;
  }

  //标题
  .title {
    text-align: center;
    margin-bottom: 36px;
    font-size: 18px;
    font-weight: bold;
    // display: inline-block;
    letter-spacing: 1.5px;
  }

  .module-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 4px solid #1890ff;
    line-height: 20px;
    font-size: 16px;
    padding-left: 6px;
    height: 18px;
  }

  // 域名检测
  .search-content {
    text-align: center;
  }

  //网站输出
  .iframe-container {
    height: 500px;
    box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
    overflow-y: scroll;
    overflow-x: hidden;
    .iframeA {
      position: absolute;
      transform: scale(0.5, 0.5) translate(-50%, -50%);
      width: 200%;
      height: 200%;
      top: 0;
      left: 0;
    }
  }

  .check-empty {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .har-upload-list-inline {
    .fa-upload {
      .fa-upload-list {
        .fa-upload-list-item {
          float: left;
          width: 200px;
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
